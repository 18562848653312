import { NextPage } from "@app-routes";
import { MeDocument, MeQuery, MeQueryVariables, useLoginMutation } from "@gql-schema";
import { LoginForm, LoginFormProps, mapToInput } from "@main-zone/forms/login-form";
import { Helmet } from "@shared/components/helmet/helmet";
import { LoginScreen } from "@shared/components/screens/login-screen";
import { clearCache, initializeApollo } from "@shared/lib/apollo";
import { ErrorService } from "@shared/services/error-service";
import { queryParamToString } from "@uxf/router";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { routes } from "../../../routes/routes";

export const Page: NextPage<"main-zone/login"> = () => {
    const { push, query } = useRouter();

    const [login] = useLoginMutation();

    const redirectTo = queryParamToString(query.redirect);
    const onLogin = useCallback<LoginFormProps["onSubmit"]>(
        async (values) => {
            try {
                await clearCache();

                await login({ variables: mapToInput(values) });

                await (redirectTo !== "" ? push(redirectTo) : push(routes["main-zone/index"]));
            } catch (e) {
                ErrorService.handleError(e);
            }
        },
        [login, push, redirectTo],
    );

    return (
        <LoginScreen
            description={
                "Pro vstup do\xa0aplikace je potřeba se přihlásit. Pokud ještě nejsi registrován, ozvi se odpovědné osobě ve\xa0svém klubu."
            }
            heading="Vítej zpět"
        >
            <Helmet noIndex title="Přihlášení" />
            <LoginForm onSubmit={onLogin} id="login-form" />
        </LoginScreen>
    );
};

Page.noHistory = true;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
    const apolloClient = initializeApollo(ctx);
    try {
        await apolloClient.query<MeQuery, MeQueryVariables>({
            query: MeDocument,
        });

        return {
            redirect: {
                permanent: false,
                destination: "/",
                props: {},
            },
        };
    } catch (e) {
        return {
            props: {},
        };
    }
};
