import { BodyBackground } from "@shared/components/body-background/body-background";
import React, { CSSProperties, FC, PropsWithChildren } from "react";

interface Props {
    description: string;
    heading: string;
    image?: "boy-standing" | "girl-standing";
}

export const LoginScreen: FC<PropsWithChildren<Props>> = ({
    children,
    description,
    heading,
    image = "girl-standing",
}) => (
    <div className="flex min-h-screen flex-col justify-center overflow-hidden bg-gray-background text-gray-dark sm:py-10">
        <BodyBackground variant="gray" />
        <header className="container relative mx-auto mt-6 flex h-fit min-h-[theme(spacing.80)] w-full items-center px-4 pb-8 pt-6 sm:max-w-tight sm:items-end sm:px-2 sm:pb-10 sm:pt-0">
            <div
                className={`absolute bottom-0 left-0 h-full max-h-[theme(spacing.80)] w-auto bg-[--bg-image] bg-contain bg-no-repeat ${
                    image === "boy-standing" ? "right-0" : "-right-6"
                }`}
                style={
                    {
                        "--bg-image": `url(/static/images/${image}.svg)`,
                    } as Partial<CSSProperties>
                }
            />
            <div className="relative w-full pr-40 xs:max-w-sm xs:pr-0">
                <h1 className="ui-typo-h2 xs:ui-typo-h1 mt-6">{heading}</h1>
                {!!description && <p className="ui-typo-body mt-4 text-gray-muted sm:mt-3">{description}</p>}
            </div>
        </header>
        <div className="container mx-auto flex w-full grow flex-col sm:max-w-tight sm:grow-0">
            <main className="flex w-full grow flex-col rounded-t-3xl bg-white px-4 text-gray-dark ring-2 ring-gray-light sm:mx-auto sm:max-w-tight sm:grow-0 sm:rounded-2xl sm:px-6">
                {children}
            </main>
        </div>
    </div>
);
