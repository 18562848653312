import { Link } from "@app-routes";
import { SubmitButton } from "@shared/forms/components/submit-button/submit-button";
import { TextInput } from "@shared/forms/components/text-input/text-input";
import { TextLink } from "@ui/components/text-link/text-link";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import React, { FC, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { LoginFormData, LoginFormProps } from "./types";

export const LoginForm: FC<PropsWithChildren<LoginFormProps>> = ({ onSubmit, id }) => {
    const isMounted = useIsMounted();
    const { handleSubmit, control } = useForm<LoginFormData>();

    return (
        <form className="ui-form" id={id} onSubmit={handleSubmit(onSubmit)}>
            <TextInput
                control={control}
                disabled={!isMounted}
                form={id}
                label="E-mail, kterým ses registroval"
                name="email"
                placeholder="vyplň svůj e-mail"
                required
                requiredMessage="Tady vyplň svůj e-mail."
                type="email"
            />
            <TextInput
                control={control}
                disabled={!isMounted}
                form={id}
                label="Heslo"
                name="password"
                placeholder="vyplň svoje heslo"
                required
                requiredMessage="Tady vyplň své heslo."
                type="password"
            />
            <p className="mb-8 text-right text-gray-muted">
                Zapomněl jsi heslo?{" "}
                <Link legacyBehavior route="main-zone/forgotten-password" passHref>
                    <TextLink className="font-semibold text-primary">Klikni zde</TextLink>
                </Link>
                .
            </p>
            <SubmitButton>Přihlásit se</SubmitButton>
        </form>
    );
};
